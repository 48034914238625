@import 'styles/colors';
@import 'styles/variables';

.alert-wrapper {
  height: 100% !important;

  padding: 0px !important;
  // width:50px;
  .user-button {
    border-radius: 0px;
    height: 100% !important;
    padding: 0px !important;
    min-width: 50px;
    justify-content: flex-start;
    display: flex;
    // @media (min-width: $BREAKPOINT_SMARTPHONE) {
    //   border-left: 1px solid white;
    // }

    border-left: 0px;

    span {
      height: 100%;
      div {
        height: 100%;
        width: 50px;
        svg {
          padding: 13px;
        }
      }
    }

    .alert-number {
      background: #db7a7d;
      width: 37px;
      height: 18px;
      margin-right: 10px;
      color: white;
      font-size: 10px;

      @media (max-width: $BREAKPOINT_SMARTPHONE) {
        width: 24px;
        height: 24px;
        font-size: 0.8rem;
      }
    }
  }
}
.no-border {
  border: 0px !important;
}

.alert-header {
  color: white;
  text-transform: capitalize;
  width: 40px !important;
  font-size: 14px;
  display: flex;
  align-items: center;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    display: none;
  }
}

.view-alerts {
  color: #db7a7d;
  font-family: 'SagoeBold';
  font-size: 0.9rem;
}

.alert-status {
  text-align-last: left;
  margin-bottom: -3px;
  font-family: 'SagoeBold';
}

.alert-date {
  text-align-last: left;
  margin-bottom: -3px;
  font-size: 0.8rem;
}

.user-option-selection {
  width: 400px !important;
  .MuiMenuItem-root {
    padding-left: 12px !important;
    height: 48px;
    font-size: 14px !important;
  }

  .MuiPaper-root {
    background-color: white;
  }
}
