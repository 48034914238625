@import 'styles/colors';
@import 'styles/variables';

.header-wrapper {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 50px;
  background: #1e201f;
  z-index: 5;
  display: flex;
  flex-flow: row wrap;
}
.tecma-elements {
  height: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
  .image-tecma-wrapper {
    display: flex;
    height: 100%;
    width: 56px;
    border-right: 2px solid white;
    .image-tecma {
      width: 24px;
      height: 24px;
      margin: auto;
    }
  }
  .crm-wrapper {
    display: flex;
    font-size: 14px;
    color: white;
    width: 85px;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }
}
.desktop-elements {
  height: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-end;
  .language-selector {
    height: 50px;
    padding: 0px !important;
    border-left: 1px solid white !important;
    margin-top: auto;
    margin-bottom: auto;
    color: white !important;
    border-radius: 0px !important;
    min-width: 51px;
    font-size: 14px;
  }
}

.card-bread {
  font-size: $BIG_FONT;
  color: $USER_BACKGROUND;
}

.card-title {
  color: white;
  font-size: $BIG_FONT !important;
  font-weight: $SEMI_BOLD;
}

.lang-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: $BREAKPOINT_MD) {
    position: absolute;
    top: -50px;
    right: 0px;
    margin-left: 10px;
  }
}

.mobile-drawer {
  color: white !important;
  border-radius: 0px !important;
  border-left: 1px solid white !important;
  height: 100%;
  padding: 12px !important;
}

.no-border {
  border: 0px !important;
}
.corner-positioning {
  right: 0;
  left: unset !important;
}

.language-option-selection {
  width: 150px !important;
  .MuiMenuItem-root {
    padding-left: 12px !important;
    height: 48px;
    font-size: 14px !important;
  }
}
