@import 'styles/colors.scss';
@import 'styles/main.scss';

.indicator {
  display: flex;
  justify-content: center;
  background-color: transparent !important;
  height: 4px;
  div {
    max-width: 90px;
    width: 100%;
    background-color: $COLOR_PRIMARY;
  }
}

.logo-navbar {
  width: $LOGO_WIDTH_HUGE;
}

.appbar {
  background-color: $BACKGROUND_PAGE !important;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start !important;
}

.permanent-drawer {
  overflow: hidden;
  width: 200px !important;
  transition: width 0.5s !important;
}
.permanent-drawer-close {
  overflow: hidden;
  width: 56px !important;
  transition: width 0.5s !important;
}

.permanent-drawer-mobile {
  overflow: hidden;
  width: 82% !important;
}

.drawer-button {
  width: 100% !important;
  height: 100%;
  padding: 0px !important;
  border-radius: 0px !important;
  text-transform: none !important;
  .justify-left {
    justify-content: left !important;
    text-align: center;
    margin-left: 8px;
    margin-right: 10px;
    font-size: 14px;
    .navbar-image-size {
      padding-left: 8px;
      padding-top: 3px;
      padding-bottom: 4px;
      width: 24px;
      height: 24px;
    }
  }
}

.hamburger-button {
  width: 24px !important;
  height: 24px !important;
  padding: 0px !important;
  margin-left: 16px !important;
  margin-top: 85px !important;
}
.image-drawer {
  width: $LOGO_WIDTH_HUGE;
  margin-top: 50px;
  align-self: center;
  transition: width 0.1s;
  max-height: 120px;
}
.image-drawer-mobile {
  width: $LOGO_WIDTH_HUGE;
  margin-top: 85px;
  align-self: center;
  transition: width 0.1s;
}
.image-drawer-small {
  width: 32px;
  margin-top: 50px;
  align-self: center;
  transition: width 0.1s;
}
.navbar-list-open {
  margin-top: 40px !important;
}
.navbar-list-close {
  margin-top: 84px !important;
}
.image-tecm-container {
  margin-bottom: 20px;
  margin-top: auto;
  text-align: center;

  .image-tecma {
    width: $LOGO_WIDTH * 2;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
  }
}
.first-drawer-of-list {
  border-top: 1px solid #cacbd3;
}

.language-buttons {
  width: max-content;
  margin-top: 50px;
  padding-left: 70%;
}
