.newsletter-modal {
  width: 80vw;
  padding: 1.5rem 1rem !important;
  max-width: 32rem !important;
  background-color: white !important;
  gap: 2rem;
  &__title {
    padding: 0 !important;
  }
  &__radio-buttons {
    margin-bottom: 1rem;
    gap: 1.25rem;
    justify-content: center;
    label {
      margin: 0;
      gap: 0.5rem;
    }
    .MuiSvgIcon-root {
      width: 1.875rem;
      height: 1.875rem;
    }
    .MuiRadio-root {
      padding: 0;
    }
  }
  &__description {
    text-align: center;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    strong {
      font-family: 'SagoeBold';
    }
  }
  &__loader {
    height: 1rem !important;
    width: 1rem !important;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    max-width: 20.5rem;
  }
  &__agreement {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 0.3125rem;
    &__text {
      text-align: start;
      font-size: 0.875rem;
      a {
        color: #1e201f;
        font-family: 'SagoeBold';
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-decoration-line: underline;
      }
    }
    .MuiCheckbox-root {
      padding: 0;
    }

    .input-checkbox-label {
      display: none;
    }
    .MuiIconButton-root.Mui-disabled {
      background-color: #f1f1f1;
      .MuiSvgIcon-root {
        fill: #f1f1f1;
      }
    }
  }
  &__privacy {
    width: 1.406rem;
    height: 1.406rem;
    &.MuiCheckbox-root.Mui-checked.MuiCheckbox-colorSecondary.Mui-disabled {
      color: #f1f1f1 !important;
      background-color: #b9b9b9 !important;
    }
  }
  &__error {
    color: #f84437;
    text-align: center;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02681rem;
  }
  .modal-actions {
    padding-bottom: 0 !important;
    gap: 1.125rem;
    button {
      margin: 0 !important;
      color: #fff;
      text-align: center;
      font-family: SagoeRegular;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.02681rem;
      &.save {
        background-color: #6bc1b4;
        transition: all 300ms;
        &[disabled] {
          background-color: #cacaca;
          cursor: not-allowed;
        }
      }
    }
  }
}
