@import 'styles/colors';
@import 'styles/variables';

/* stripped table */
tbody.MuiTableBody-root > :nth-of-type(2n) {
  background-color: $BACKGROUNDS !important;
}

#lens-in-table {
  div {
    display: flex;
  }
}
