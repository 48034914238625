@import 'styles/colors';
@import 'styles/variables';

.notification-wrapper {
  height: 100% !important;

  padding: 0px !important;
  // width:50px;
  .user-button {
    border-radius: 0px;
    height: 100% !important;
    padding: 0px !important;
    min-width: 50px;
    justify-content: flex-start;
    display: flex;
    border-left: 0px;
    .dot {
      height: 5px;
      width: 5px;
      background-color: #66d7c6;
      border-radius: 50%;
      display: inline-block;
      margin-right: 12px;
      margin-bottom: 10px;
    }

    span {
      height: 100%;
      div {
        height: 100%;
        width: 40px;
        svg {
          padding: 13px;
          padding-right: 0px;
          padding-top: 16px;
        }
      }
    }
  }
}

.no-border {
  border: 0px !important;
}

.notification-header {
  color: white;
  text-transform: capitalize;
  width: 40px !important;
  font-size: 14px;
  display: flex;
  align-items: center;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    display: none;
  }
}

.user-option-selection {
  width: 400px !important;
  .MuiMenuItem-root {
    padding-left: 12px !important;
    height: 48px;
    font-size: 14px !important;
  }

  .MuiPaper-root {
    background-color: white;
  }
}

.view-notifications {
  color: #6bc1b4;
  font-family: 'SagoeBold';
  font-size: 0.9rem;
}

.notification-status {
  text-align-last: left;
  margin-bottom: -3px;
  font-family: 'SagoeBold';
}

.notification-date {
  text-align-last: left;
  margin-bottom: -3px;
  font-size: 0.8rem;
}
