.error-page {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  &-title {
    font-size: 2.25rem;
    font-family: 'SagoeBold';
    color: #4d4d4d;
  }
  &-message {
    font-size: 1.25rem;
    line-height: 2.5rem;
    max-width: 33rem;
    width: 100%;
    margin: 0 auto 2rem;
    color: #666666;
  }
  &-logo {
    width: 10%;
    height: auto;
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
    min-width: 10rem;
  }
  &-button.MuiButton-root {
    text-transform: capitalize;
    font-weight: 600;
    height: 3rem;
    width: 20rem;
    max-width: 90%;
    font-size: 1rem;
    color: #4c4c4c;
    border-color: #4d4d4d;
  }
}
