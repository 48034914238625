@import 'styles/colors';
@import 'styles/variables';

.avatar-wrapper {
  height: 100% !important;
  display: flex;
  .user-button {
    border-radius: 0px;
    height: 100% !important;
    padding: 0 !important;
    min-width: 3.125rem;
    justify-content: flex-start;
    border-left: 0;
    @media (min-width: $BREAKPOINT_SMARTPHONE) {
      border-left: 1px solid $COLOR_SECONDARY;
    }
    .user-logo div {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 0.875rem;
    }
  }
}
.no-border {
  border: 0 !important;
}

.logged-user-name {
  display: flex;
  align-items: center;
  color: $COLOR_SECONDARY;
  text-transform: capitalize;
  padding-right: 0.875rem;
  font-size: 0.875rem;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    display: none;
  }
}

.user-option-selection {
  width: 9.375rem !important;
  .MuiMenuItem-root {
    padding-left: 0.75rem !important;
    height: 3rem;
    font-size: 0.875rem !important;
  }
}
