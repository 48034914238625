@import 'styles/colors';
@import 'styles/variables';

.support-wrapper {
  height: 100% !important;
  display: flex;
  .link-button {
    border-radius: 0;
    height: 100% !important;
    padding: 0 !important;
    min-width: 3.125rem;
    justify-content: flex-start;
    border-left: 0;
    @media (min-width: $BREAKPOINT_SMARTPHONE) {
      border-left: 1px solid $COLOR_SECONDARY;
    }
    .support-logo div {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 0.875rem;
    }
    &-text {
      display: flex;
      align-items: center;
      color: $COLOR_SECONDARY;
      text-transform: capitalize;
      padding-right: 0.875rem;
      font-size: 0.875rem;
      @media (max-width: $BREAKPOINT_SMARTPHONE) {
        display: none;
      }
    }
  }
}
