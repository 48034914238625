@import 'styles/colors';
@import 'styles/variables';
@import 'styles/fontfaces';

body {
  font-family: $DEFAULT_FONT;
  font-weight: $LIGHT;
  color: $DEFAULT_FONT_COLOR;
  margin: 0px !important;
  background-color: $BACKGROUND_PAGE;
  padding: 0 !important;
  -webkit-font-smoothing: antialiased;
}

b {
  font-family: $DEFAULT_FONT_BOLD;
}

strong {
  font-family: $DEFAULT_FONT_BOLD;
}

.default-font {
  font-family: $DEFAULT_FONT;
}

.small-font-6 {
  font-size: 6px;
}

.small-font-8 {
  font-size: 8px;
}

.small-font-9 {
  font-size: 9px;
}

.small-font-10 {
  font-size: 10px;
}

.small-font-11 {
  font-size: 11px;
}

.small-font-12 {
  font-size: 12px;
}

.elements-spacing {
  margin: 15px;
}
.elements-spacing-small {
  margin-left: 10px;
}
.elements-spacing-title-small {
  margin-top: 10px;
  margin-left: 10px;
}
.small-font-14 {
  font-size: 14px;
}
.font-18 {
  font-size: 18px;
}

.M-10 {
  margin: 10px;
}
.MT-0 {
  margin-top: 0px;
}

.MT-10 {
  margin-top: 10px;
}

.MR-10 {
  margin-right: 10px !important;
}

.ML-10 {
  margin-left: 10px;
}

.MTB-10 {
  margin-top: 10px;
  margin-bottom: 10px !important;
}

.MR-30 {
  @media (min-width: $BREAKPOINT_MD) {
    margin-right: 30px;
  }
}

.width-100 {
  width: 100%;
}

.full-width {
  @extend .width-100;
  width: -webkit-fill-available;
  width: -moz-available;
}
.border {
  border: rgba(0, 0, 0, 0.54) !important;
  border-style: solid !important;
  border-width: 1px !important;
  textarea {
    padding-left: 30px !important;
    height: 6rem !important;
  }
}

.height {
  height: 140px !important;
}

.flex {
  display: flex;
}

.flex-column {
  @extend .flex;
  flex-direction: column;
}

.flex-column-justified {
  @extend .flex-column;
  justify-content: space-between;
}

.flex-centered {
  @extend .flex;
  align-items: center;
}

.flex-justify-center {
  @extend .flex;
  justify-content: center;
}

.flex-centered-between {
  @extend .flex-centered;
  justify-content: space-between;
}

.flex-centered-between-wrapped {
  @extend .flex-centered-between;
  flex-wrap: wrap;
}

.mobile-flex-column {
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    flex-direction: column;
  }
}

.flex-wrap-container {
  @extend .flex;
  flex-wrap: wrap;
}

.flex-container-end {
  @extend .flex;
  justify-content: flex-end;
}

.flex-row-3 {
  flex-basis: 30%;
}

.input-text {
  background-color: white;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.light {
  @extend .default-font;
  font-weight: $LIGHT !important;
}

.regular {
  @extend .default-font;
  font-weight: $REGULAR !important;
}

.medium {
  @extend .default-font;
  font-weight: $MEDIUM !important;
}

.semi-bold {
  @extend .default-font;
  font-weight: $SEMI_BOLD !important;
}

.bold {
  @extend .default-font;
  font-weight: $BOLD !important;
}

.black {
  @extend .default-font;
  font-weight: $BLACK !important;
}

.unselected {
  color: $DISABLED !important;
}

.selected {
  color: $DEFAULT_TEXT !important;
}

.backdrop-color {
  background-color: $BACKGROUNDS;
}

.backdrop-transparent {
  background-color: $BACKGROUNDS_TRANSPARENT;
}

.default-background {
  background-color: $BACKGROUNDS;
}

.more-z {
  z-index: 4 !important;
}

.color-white {
  color: white !important;
  fill: white !important;
}

.libero {
  @extend .color-white;
  background-color: $LIBERO !important;
}

.interesse {
  @extend .color-white;
  background-color: $INTERESSE !important;
}

.proposta {
  @extend .color-white;
  background-color: $PROPOSTA !important;
}

.compromesso {
  @extend .color-white;
  background-color: $COMPROMESSO !important;
}

.rogitato {
  @extend .color-white;
  background-color: $ROGITATO !important;
}

.affittato {
  @extend .color-white;
  background-color: $AFFITTATO !important;
}

.opzionato {
  @extend .color-white;
  background-color: $OPZIONATO !important;
}

.richiesto {
  @extend .color-white;
  background-color: $RICHIESTO !important;
}
.cancellato {
  @extend .color-white;
  background-color: $CANCELLATO !important;
}
.riservato {
  @extend .color-white;
  background-color: $RISERVATO !important;
}
.scaduto {
  @extend .color-white;
  background-color: $EXPIRED !important;
}
.sospeso {
  background-color: $PENDING !important;
  background-image: url('../images/assets/exclamation-point.svg');
  background-repeat: no-repeat;
  background-position: center;
}
.invalido {
  background-color: $INVALIDO !important;
  background-image: url('../images/assets/cross.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 45%;
}

.ex_cliente {
  @extend .color-white;
  background-color: $EX_CLIENT !important;
}
.in_corso {
  @extend .color-white;
  background-color: $IN_CORSO !important;
}
.confermata {
  @extend .color-white;
  background-color: $CONFERMATO !important;
}
.non_confermata {
  @extend .color-white;
  background-color: $NON_CONFERMATO !important;
}
.controfirmata {
  @extend .color-white;
  background-color: $CONTROFIRMATO !important;
}
.validata {
  @extend .color-white;
  background-color: $VALIDATO !important;
}

.search-button {
  @extend .color-white;
  background-color: $SEARCH_BUTTON !important;
  padding: 10px !important;
}

.modify-button {
  @extend .search-button;
}

.add-button {
  @extend .search-button;
}

.remove-button {
  @extend .color-white;
  padding: 10px !important;
  background-color: $EDITING_COLOR !important;
}
.border-point {
  display: block;
  width: 25px;
  height: 25px;
  border-radius: 20px;
  margin-right: 5px;
}
.icons-in-button {
  width: 18px;
  height: 18px;
  line-height: 18px;
  font-size: 18px !important;
  padding: 0px !important;
}

.pointer {
  cursor: pointer;
}

.grab {
  cursor: grab;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 20px;
}

#formDetailNotification {
  .input-wrapper-container {
    height: max-content;
  }
}

.event-store-background {
  background-color: $EVENT_STORE_BACKGROUND !important;
  padding-top: 10px;
  padding-bottom: 10px;
}
.event-video-background {
  background-color: $EVENT_VIDEO_BACKGROUND !important;
  padding-top: 10px;
  padding-bottom: 10px;
}
.event-call-background {
  background-color: $EVENT_CALL_BACKGROUND !important;
  padding-top: 10px;
  padding-bottom: 10px;
}
.form-width {
  width: 95% !important;
  margin: 5px;
}
.smaller-font {
  font-size: $SMALL_LABEL_FONT;
}

.flex-grow-1 {
  flex-grow: 1;
}

.modal-custom-container {
  padding: 5px 10px;
  width: 500px; //34.72%
  height: 250px;
  background-color: white !important;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    margin: 0;
  }
}

.modal-manage-proposal {
  padding: 5px 10px;
  width: 500px;
  height: unset;
  background-color: white !important;
  overflow: visible;
  &.MuiDialog-paper {
    overflow-y: initial;
  }
  .modal-action-button,
  .modal-cancel-button {
    padding: 20px 10px;
    margin: 10px !important;
    min-width: 80px !important;
  }
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    margin: 0;
  }
  &-calendar {
    position: relative;
    .position-of-tuning-date-range {
      position: initial;
      .open-calendar-button,
      .open-calendar-button-focus {
        width: auto !important;
        padding: 0 20px !important;
      }
      .date-range-calendar {
        top: 35px;
        left: calc(-50% - 125px);
        right: unset;
      }
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: space-between;
    padding: 0 !important;
    width: 100%;
    overflow: visible;
    &.MuiDialogContent-root {
      overflow-y: initial;
    }
    &-text {
      font-size: 16px;
      padding: 0;
      width: 85%;
      max-width: 275px;
      span {
        font-family: 'SagoeBold';
      }
    }
  }
  &-title {
    color: #6bc1b4;
    font-size: 28px;
    &.warning {
      color: #db7a7d;
    }
  }
}

.modal-save-quote-container {
  padding: 5px 10px;
  width: 500px;
  height: unset;
  background-color: white !important;
  .modal-action-button,
  .modal-cancel-button {
    padding: 20px 10px;
    margin: 10px !important;
    min-width: 80px !important;
  }
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    margin: 0;
  }
  .modal-save-quote-content {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: space-between;
    padding: 0 !important;
    width: 100%;
    position: relative;
    &-text {
      font-size: 16px;
      padding: 0;
      width: 85%;
      max-width: 275px;
      span {
        font-family: 'SagoeBold';
      }
    }
    &-resume {
      background-color: #f6f7f9;
      border-radius: 20px;
      position: relative;
      padding: 25px;
      box-sizing: border-box;
      width: 75%;
      max-width: 275px;
    }
    &-ribbon {
      border-radius: 6px;
      position: absolute;
      width: 20px;
      height: 20px;
      padding: 10px;
      top: 20px;
      left: -20px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #6bc1b4;
      -webkit-box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.3);
      box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.3);
      &::before {
        content: '';
        position: absolute;
        top: 100%;
        left: 5px;
        border-top: 5px solid #008672;
        border-left: 15px solid transparent;
      }
    }
    .quote-field {
      margin: 0;
      ~ .quote-field {
        margin: 10px 0 0;
      }
      &-title {
        font-family: 'SagoeBold';
        font-size: 14px;
        margin: 0;
      }
      &-value {
        margin: 0;
        font-size: 14px;
        > p {
          margin: 0;
          font-family: 'Sagoe';
          span {
            font-family: 'SagoeRegular';
          }
        }
        .old-dates {
          font-size: 12px;
        }
      }
    }
  }
  .modal-save-quote-title {
    color: #6bc1b4;
    font-size: 28px;
  }
}

.modal-warning-quote-container {
  padding: 5px 10px;
  width: 500px;
  height: unset;
  background-color: white !important;
  .modal-action-button,
  .modal-cancel-button {
    padding: 20px 10px;
    margin: 10px !important;
    min-width: 80px !important;
  }
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    margin: 0;
  }
  .modal-warning-quote-content {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: space-between;
    padding: 0 !important;
    width: 100%;
    position: relative;
    &-info {
      max-width: 120px;
    }
    &-list {
      padding-inline-start: 20px;
    }
    &-text {
      font-size: 16px;
      padding: 0;
      width: 85%;
      max-width: 275px;
      span {
        font-family: 'SagoeBold';
      }
    }
    &-resume {
      background-color: #f5e1e1;
      border-radius: 10px;
      margin-top: 20px;
      position: relative;
      padding: 50px 40px 20px;
      box-sizing: border-box;
      width: max-content;
      min-width: 100px;
    }
    &-ribbon {
      border-radius: 2px;
      position: absolute;
      width: 40px;
      height: 40px;
      padding: 10px;
      top: -20px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ed5d58;
      -webkit-box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.3);
      box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.3);
    }
  }
  .modal-warning-quote-title {
    color: #db7a7d;
    font-size: 28px;
  }
}

.modal-generic-quote-container {
  padding: 0.313rem 0.625rem;
  width: 31.25rem;
  background-color: white !important;
  .modal-action-button,
  .modal-cancel-button {
    padding: 1.25rem 0.625rem;
    margin: 0.625rem !important;
  }
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    margin: 0;
  }

  .modal-generic-quote-content {
    margin: 0.5rem 0 1rem;
  }

  .modal-generic-quote-title {
    color: $EX_CLIENT;
    font-size: $BIG_FONT;
  }
}

.modal-container-refuse-proposal {
  padding: 5px 10px;
  width: 500px; //34.72%
  min-height: 300px;
  max-height: 500px;
  background-color: white !important;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    margin: 0;
  }
}

.delete-alert {
  margin-top: 10px;
  color: red;
}

.add-appartments-modal-content {
  min-height: 200px;
  min-width: 400px;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    min-width: unset;
  }
}

.custom-listbox {
  background-color: white;
  color: black;
  padding: 0px !important;
}
.custom-listbox:hover {
  background-color: white;
}

.list-divider {
  border-bottom: 1px solid $USER_DATA_COLOR;
}

.loader-wrapper {
  min-height: 60px;
}

.absolute-position {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.fixedPosition {
  position: fixed;
  z-index: 1201 !important;
}

.absolute-position-relative-content-loader {
  position: relative;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
}

.relative {
  position: relative;
}

.snackbar-body {
  color: #6bc1b4;
  font-weight: 400;
  background-color: #f8f8f8;
  font-family: 'SagoeRegular';
  text-align: center;
  padding: 10px;
  width: 300px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.snackbar-body-error {
  color: #f35959;
  font-weight: 400;
  background-color: #f8f8f8;
  font-family: 'SagoeRegular';
  text-align: center;
  padding: 10px;
  width: 300px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputAdditional {
  padding: 0 10px;
}

.ellipsis {
  text-overflow: ellipsis;
  // margin-bottom: 7px;
}

.justify-center {
  justify-content: center;
}

.link-on-hover {
  transition: 0.5s;
  &:hover {
    color: $EDITING_COLOR !important;
  }
}

.privacy-divider {
  margin-top: 20px;
}

.additional-divider {
  margin-top: 20px;
  margin-bottom: 10px;
}

.button-right {
  margin-left: 95%;
}
.align-right {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 15px !important;
  margin-left: auto !important;
  display: flex !important;
  height: 50px !important;
  width: 50px !important;
}

.new_input {
  margin: 15px !important;
}

// .MuiFormHelperText-root.Mui-error {
//   margin-top: 20px;
// }

.button-findPdf {
  @extend .search-button;
  margin: 5px 10px;
}
.button-findPdf-menu {
  @extend .search-button;
  margin-right: 10px !important;
}
// .menu-item {
//   margin-top: 10px;
// }
// .menu-item-dropdown {
//   min-width: 200px;
// }
.pdfTitle {
  display: contents;
}
.upload-button {
  margin-right: 10px !important;
}
.error-import-modal-title {
  font-weight: bold;
  font-size: 16px;
  margin-top: 10px !important;
}
.download-button {
  margin-right: 10px !important;
}

.loader-pdf-proposal {
  background-color: transparent !important;
  margin: 5px 10px;
  position: relative;
  height: 40px;
  width: 40px;
}

.expired-date-input {
  width: 125px;
  margin-bottom: 0px !important;
  .MuiInput-input {
    text-align: center !important;
  }
}

.main-container.no-shadow-4-screenshot * {
  box-shadow: none;
}

.text-align-left {
  text-align: left;
}
.line-height-initial {
  line-height: initial;
}
.body-wrapper-open {
  position: relative;
  transition: margin 1s;
  @media (min-width: $BREAKPOINT_SMARTPHONE) {
    margin-top: $DEFAULT_MARGIN;
    margin-right: 16px;
    margin-bottom: 10px;
    margin-left: 216px;
  }
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    margin-bottom: 8px;
  }
}
.body-wrapper-close {
  position: relative;
  transition: margin 1s;
  @media (min-width: $BREAKPOINT_SMARTPHONE) {
    margin-top: $DEFAULT_MARGIN;
    margin-right: 16px;
    margin-bottom: 10px;
    margin-left: 72px;
  }
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    margin-bottom: 8px;
  }
}
.page-title {
  flex-direction: row;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 18px;
  font-weight: 600;
  color: #989898;
  margin-top: 80px;
  margin-bottom: 24px;
  margin-left: 16px;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    margin-top: 66px;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 2.5em;
  }
}
.documentId-chip-item {
  margin: 10px;
  font-weight: bold;
}

.file-uploader-container {
  width: fit-content;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  .uploaded-files > ol > li > div {
    display: flex;
    align-items: center;
  }
}

.configQuote-button {
  position: absolute !important;
  top: 0;
  right: 145px;
  border: 1px solid #ebe9eb !important;
  text-transform: none !important;
  font-size: 12px !important;
  border-radius: 0px;
  width: 120px;
  height: 35px;
  color: #989898 !important;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    width: 36px;
    min-width: 36px !important;
  }
}
.configQuote-button:hover {
  color: #6bc1b4 !important;
  background-color: white !important;
  border: 1px solid #6bc1b4 !important;
}

.screenshot-button {
  position: absolute !important;
  top: 0;
  right: 14px;
  border: 1px solid #ebe9eb !important;
  text-transform: none !important;
  font-size: 12px !important;
  border-radius: 0px;
  width: 120px;
  height: 35px;
  color: #989898 !important;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    min-width: 36px !important;
    width: 36px !important;
  }
}
.add-notification-button {
  position: absolute !important;
  top: 0;
  right: 14px;
  border: 1px solid #ebe9eb !important;
  text-transform: none !important;
  font-size: 12px !important;
  border-radius: 0px;
  width: 150px;
  height: 35px;
  color: #989898 !important;
  background-color: #6bc1b4 !important;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    min-width: 36px !important;
    width: 36px !important;
  }
}

.add-notification-inline {
  display: inline-flex;
  flex-direction: row;
  // position: relative;
}

.download-attachment-button {
  border: 1px solid #ebe9eb !important;
  text-transform: none !important;
  font-size: 12px !important;
  border-radius: 0px;
  width: 150px;
  height: 35px;
  color: #a4a4a4 !important;
  background-color: #f0f0f0 !important;
  margin-left: 18px;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    min-width: 36px !important;
    width: 36px !important;
  }
}

.download-attachment-button:hover {
  color: black !important;
}

.screenshot-button:hover {
  color: #6bc1b4 !important;
  background-color: white !important;
  border: 1px solid #6bc1b4 !important;
}

// .add-notification-button:hover {
//   color: #6bc1b4 !important;
//   background-color: white !important;
//   border: 1px solid #6bc1b4 !important;
// }

.notification-tag {
  input,
  textarea {
    color: #6bc1b4 !important;
  }
}

.border-grid {
  @media (min-width: $BREAKPOINT_MD) {
    height: 377px;
  }
  padding: 16px;
  @media (max-width: $BREAKPOINT_MD) {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    padding: 8px 16px;
  }
}

.flex-row {
  @extend .flex;
  flex-direction: row;
}

.flex-1 {
  flex: 1;
}

.flex-container-start {
  @extend .flex;
  justify-content: flex-start;
}
.flex-container-end {
  @extend .flex;
  justify-content: flex-end;
}

.bottom-card {
  padding: 16px;
  @media (max-width: $BREAKPOINT_MD) {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    padding: 8px 16px;
  }
}

.font-bold {
  font-family: 'SagoeBold';
}

.font-regular {
  font-family: 'SagoeRegular';
}

.circle-in-table {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: inline-block;
}

.dashboard-info {
  display: flex;
  @media (max-width: $BREAKPOINT_LG) {
    flex-flow: column;
  }
  margin-left: 16px;
  margin-right: 18px;
  margin-bottom: 16px;
  margin-top: 40px;
  width: 100%;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .dashboard-info-title {
    @extend .black;
    font-size: 18px;
    text-transform: capitalize;
    @media (max-width: $BREAKPOINT_SMARTPHONE) {
      font-size: 16px;
    }
  }
  .dashboard-info-items {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    @media (min-width: $BREAKPOINT_LG) {
      align-items: flex-end;
    }
    @media (max-width: $BREAKPOINT_LG) {
      justify-content: flex-start;
      padding-top: 24px;
    }
    @media (max-width: $BREAKPOINT_SMARTPHONE) {
      padding-top: 16px;
    }
  }
}

.anchor-link {
  color: #6bc1b4;
  text-decoration: none;
  border-bottom: 2px solid #6bc1b4;
}

.table-button {
  background-color: #6bc1b4 !important;
  font-size: 12px !important;
  color: white !important;
  text-transform: capitalize !important;
  margin-bottom: 7px !important;
  margin-left: 15px !important;
  padding: 7px 15px 7px 15px !important;
  height: 36px !important;
  @media (max-width: $BREAKPOINT_SM) {
    padding: 7px !important;
    min-width: 36px !important;
    margin: 0px !important;
  }
}

.notification-table-button {
  background-color: #6bc1b4 !important;
  font-size: 12px !important;
  color: white !important;
  text-transform: capitalize !important;
  padding: 7px 15px 7px 15px !important;
  height: 44px !important;
  width: 140px !important;
  @media (max-width: $BREAKPOINT_SM) {
    padding: 7px !important;
    min-width: 36px !important;
    margin: 0px !important;
  }
  @media (max-width: $BREAKPOINT_MD) {
    padding: 7px !important;
    min-width: 36px !important;
    margin: 0px !important;
    font-size: 14px !important;
    width: 100px !important;
  }
}

.notification-table-button-position {
  @media (max-width: $BREAKPOINT_MD) {
    margin-left: auto;
    margin-right: 10px;
    align-self: center;
  }
}

.notification-table-button-dashboard {
  background-color: #6bc1b4 !important;
  font-size: 12px !important;
  color: white !important;
  text-transform: capitalize !important;
  padding: 7px 15px 7px 15px !important;
  height: 36px !important;
  align-self: center;
  @media (max-width: $BREAKPOINT_SM) {
    padding: 18px !important;
    min-width: 36px !important;
    margin: 0px !important;
  }

  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    height: 60px !important;
  }
}

.table-open-modal-button {
  min-width: 35px !important;
  padding: 10px 10px 10px 10px !important;
  border: 1px solid #e8e9eb !important;
  border-radius: 0px !important;
  background-color: white !important;
  margin-left: 6px !important;
  margin-bottom: 7px !important;
  color: #989898 !important;
  .MuiButton-label {
    height: 15px !important;
    width: 15px !important;
  }
}
.table-open-modal-button:hover {
  color: #6bc1b4 !important;
  border: 1px solid #6bc1b4 !important;
}
.table-open-modal-button-open {
  min-width: 35px !important;
  padding: 10px 10px 10px 10px !important;
  border: 1px solid #6bc1b4 !important;
  border-radius: 0px !important;
  background-color: white !important;
  margin-left: 6px !important;
  margin-bottom: 7px !important;
  color: #6bc1b4 !important;
  .MuiButton-label {
    height: 15px !important;
    width: 15px !important;
  }
}

.table-appartments-options {
  width: 142px !important;
  border: 1px solid #e8e9eb !important;

  .MuiMenuItem-root {
    background-color: white !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    padding-left: 16px !important;
    height: 40px !important;
  }
  .MuiMenuItem-root:hover {
    background-color: #6bc1b4 !important;
    color: white !important;
  }
}

.extra-space-top {
  @media (min-width: 600px) and (max-width: $BREAKPOINT_MD) {
    padding-top: 50px;
  }
}

.custom-table-action {
  display: flex;
  @media (min-width: 600px) and (max-width: $BREAKPOINT_MD) {
    position: absolute;
    top: 50px;
    right: 16px;
  }
}

.client-table-action,
.account-table-action {
  position: absolute;
  top: 0;
  right: 0;
  @media (min-width: 705px) {
    position: unset;
  }
}

.table-preventivi-action {
  @media (max-width: $BREAKPOINT_MD) {
    position: absolute;
    top: -5px;
    right: 0;
    display: flex;
    align-items: center;
  }
}

.table-title {
  text-transform: capitalize;
  @media (max-width: $BREAKPOINT_MD) {
    position: absolute;
    top: 10px;
    left: 0;
  }
}

.editable-title {
  text-transform: capitalize;
  @media (max-width: $BREAKPOINT_MD) {
    position: absolute;
    top: 5px;
    left: 0;
  }
}

.table-clients-manager-options {
  width: 142px !important;
  border: 1px solid #e8e9eb;
  .MuiMenuItem-root {
    background-color: white !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    padding-left: 16px !important;
    height: 45px !important;
  }
  .MuiMenuItem-root:hover {
    background-color: #6bc1b4 !important;
    color: white !important;
  }
}

.circle-in-table-quotes {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  display: inline-block;
  &.margin-right-eight {
    margin-right: 8px;
  }
}

.pdf-dropdown-menu {
  width: 120px !important;
  .MuiMenuItem-root {
    background-color: white !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    padding-left: 0px !important;
    height: 50px !important;
    justify-content: center;
  }
  .MuiMenuItem-root:hover {
    background-color: white !important;
  }
}

.page-back-button {
  background-color: white !important;
  color: #989898 !important;
  border: 1px solid #e8e9eb !important;
  text-transform: none !important;
  box-shadow: none !important;
  margin-right: 25px !important;
  height: 40px;
  width: 95px;
  .MuiButton-label {
    display: block;
    font-size: 14px;
  }
}

.editable-table-plus-button {
  background-color: #6bc1b4 !important;
  font-size: 12px !important;
  color: white !important;
  text-transform: capitalize !important;

  width: 80px;
  height: 36px;
  position: relative;
  top: -5px;
  right: -12px;
  @media (max-width: $BREAKPOINT_MD) {
    position: absolute;
    top: -20px;
  }
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    position: absolute;
    top: -30px;
    right: -10px;
    padding: 0 !important;
    width: 36px;
    min-width: unset !important;
    .MuiButton-label {
      width: fit-content;
    }
  }
}

.font-16 {
  font-size: 16px;
}

.MuiAutocomplete-endAdornment {
  .Mui-disabled {
    visibility: hidden;
  }
}

.MuiFormLabel-root.Mui-disabled {
  color: #989898;
  font-size: 14px;
  padding-bottom: 15px;
  display: flex;
  top: -5px;
}

.additional-label-readonly {
  color: #989898;
  font-size: 14px;
  display: flex;
  top: -5px;
}

.planimetry-icon {
  height: 100%;
  display: flex;
  align-items: center;
  width: 75px;
  justify-content: center;
  border-right: 1px solid #e8e9eb;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    width: 67px;
    padding-top: 2px;
  }
}
.planimetry-text {
  padding-left: 23px;
  font-size: 16px;
}

.image-size {
  max-width: 100%;
  max-height: 600px;
}

.magnified-container {
  background-color: white;
  cursor: zoom-in !important;
}

.zoom-options {
  height: 35px;
  justify-content: flex-end;
  margin-bottom: 30px;
  margin-top: 7px;
  position: relative;
  right: -10px;
}

.zoom-button {
  border: 1px solid #1e201f !important;
  width: 35px;
}
.zoom-button:hover {
  background-color: transparent !important;
}

.image-zoom-container {
  width: 100%;
  height: 100%;
  justify-content: center;
}

.image-size {
  max-width: 100%;
  max-height: 700px;
}

.modify-apartment-form-container {
  position: relative;
  padding: 16px;
  .MuiCollapse-container {
    position: relative;
    .MuiAccordionDetails-root {
      padding: 1rem;
    }
  }
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    padding-top: 0;
    margin-top: -5px;
  }
  .input-wrapper-container {
    margin: 4px !important;
    height: 80px;
  }
}

.status-apartment-tab {
  width: 100%;
  background-color: white;
  .status-apartment-tab-header {
    height: 80px;
    border-bottom: 1px solid #e8e9eb;
    display: flex;
    @media (max-width: $BREAKPOINT_SMARTPHONE) {
      height: 56px;
    }
    .status-text {
      display: flex;
      align-items: center;
      padding-left: 23px;
      font-size: 16px;
    }
  }
  .status-apartment-tab-content {
    position: relative;
    padding-bottom: 25px;
    .status-apartment-legend {
      display: flex;
      flex-wrap: wrap;
      padding: 1.625rem 1rem 0;
      gap: 1rem;
      .status-apartment-legend-item {
        display: flex;
        align-items: center;
        margin-top: 5px;
        .status-apartment-legend-circle {
          height: 10px;
          width: 10px;
          border-radius: 50%;
          display: inline-block;
        }
        .status-apartment-legend-label {
          margin-left: 8px;
          font-size: 16px;
          font-weight: 600;
          @media (max-width: $BREAKPOINT_SMARTPHONE) {
            font-size: 14px;
          }
        }
        .status-apartment-legend-light {
          margin-left: 8px;
          font-size: 16px;
          font-weight: 200;
          @media (max-width: $BREAKPOINT_SMARTPHONE) {
            font-size: 14px;
          }
        }
      }
    }
    .status-apartment-chip-container {
      padding: 0 1rem;
      display: flex;
      flex-wrap: wrap;
      padding-top: 2rem;
      gap: 1rem;
      .status-apartment-chip-item {
        background-color: rgba(232, 233, 235, 0.38);
        height: 32px;
        border-radius: 18px;
        max-width: 100%;
        .MuiChip-label {
          flex: 1;
        }
        .status-apartment-chip-item-circle {
          height: 32px;
          width: 32px;
          border-radius: 50%;
          display: inline-block;
          margin-left: 0px !important;
        }
        .status-apartment-chip-label {
          font-size: 12px;
          font-weight: 600;
        }
      }
    }
  }
}

.padding-add-client {
  padding: 16px;
}

.gestione-privacy-checkbox {
  display: flex;
  padding-left: 16px;
  .input-wrapper-container {
    margin: 10px !important;
  }
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    display: block;
    padding-left: 10px;
  }
  .MuiGrid-item {
    min-width: fit-content;
  }
  .MuiGrid-grid-md-4 {
    flex-basis: auto;
  }
  .input-wrapper-container {
    height: fit-content;
    .input-wrapper-input-component {
      padding-left: 0px !important;
      margin-left: 0px;
    }
  }
}
.empty-time .fc-time {
  display: none;
}
.MuiAccordionDetails-root {
  .input-wrapper-input-component {
    margin-left: 0px !important;
    @media (max-width: $BREAKPOINT_SMARTPHONE) {
      margin-left: 0px !important;
    }
  }
}
.Mui-disabled::before {
  border-bottom: 1px solid #e8e9eb;
}
.grid-add-client-container {
  .input-wrapper-icon-container {
    height: 65px;
  }
}

.add-client-general-accordion-summary {
  height: 70px !important;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    height: 55px !important;
  }
  .add-client-general-accordion-label {
    font-size: 14px;
    font-weight: 600;
    padding-left: 16px;
  }
}

.radio-button-proposal-table-yes {
  padding-left: 8px !important;
  .MuiSvgIcon-root {
    width: 30px !important;
    height: 30px !important;
  }
}

.circle-dotted {
  border: 2px dotted gray;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  display: inline-block;
}

.radio-button-icon {
  height: 20px;
  width: 20px;
  border: 1px solid black;
  border-radius: 50%;
  display: inline-block;
  margin-right: 3px;
}
.radio-button-icon-disabled {
  height: 20px;
  width: 20px;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
  display: inline-block;
  margin-right: 3px;
  background: rgba(217, 217, 217, 0.4);
}

.down-arrow {
  margin-left: 10px;
  margin-bottom: 2px;
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.up-arrow {
  margin-left: 10px;
  margin-bottom: -5px;
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.add-viewed-apartment-modal {
  background-color: white !important;
  width: 50%;
  display: flex;
  flex-flow: column;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    width: 100%;
  }
  @media (min-width: $BREAKPOINT_MD) and (max-width: $BREAKPOINT_LG) {
    width: 60%;
  }
  .add-viewed-apartment-modal-header {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e8e9eb;
    .add-viewed-apartment-modal-title {
      color: #989898;
      padding-left: 32px;
      font-family: 'SagoeRegular';
      font-size: 18px;
    }
    .add-viewed-apartment-modal-close-button {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      .close-button:hover {
        background-color: transparent;
      }
    }
  }
  .add-viewed-apartment-modal-elements {
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    .add-viewed-apartment-modal-selectors {
      max-width: 250px;
      padding-top: 16px;
      padding-left: 32px;
      label {
        font-weight: 700 !important;
      }
    }
    .add-item-modal-text-field {
      margin-left: 32px;
      margin-top: 16px;
      width: 100%;
      label {
        font-weight: 700 !important;
      }
    }
  }
  .MuiAutocomplete-tag,
  .MuiAutocomplete-tagSizeSmall {
    margin: 0px 8px 5px 0px;
    max-width: calc(100% - 6px);
    min-width: 70px;
    background: #fff;
    border: 1px solid #e8e9eb;
    font-size: 12px;
    color: #989898;
    height: 24px;
    font-family: 'SagoeRegular';
    font-weight: 400;
  }
  .MuiChip-labelSmall {
    padding-right: 16px;
  }
  .add-viewed-apartment-modal-footer {
    display: flex;
    height: 100px;
    border-top: 1px solid #e8e9eb;
    background-color: white;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem 2rem;
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    width: 100%;
    @media (max-width: $BREAKPOINT_MD) {
      justify-content: space-between;
    }
    button {
      height: 50px;
      min-width: 80px;
      font-size: 12px;
      text-transform: none;
      &:not(:first-of-type) {
        margin-left: 1rem;
      }
      &.edit-button,
      &.save-button,
      &.save-footer-button {
        background-color: #6bc1b4;
        color: white;
        transition: background-color 300ms, color 300ms;
      }
      &.save-footer-button,
      &.cancel-footer-button {
        height: 40px;
      }
      &.save-button.disabled {
        background-color: #a4c2be;
        color: #373737;
        cursor: not-allowed;
        pointer-events: all;
      }
      &.cancel-button,
      &.cancel-footer-button {
        border: 1px solid #e8e9eb;
        background-color: white;
        color: #989898;
      }
      @media (max-width: $BREAKPOINT_MD) {
        &.edit-button {
          width: 100%;
        }
        min-width: 135px;
        height: 45px;
      }
    }
  }
}

.open-quote-button {
  background-color: #6bc1b4 !important;
  color: #f6f7f7 !important;
  font-size: 12px !important;
  width: max-content;
  text-transform: none !important;
  margin-left: 1rem !important;

  &-loading {
    padding: 0 1.5625rem;
  }

  &.MuiButtonBase-root.Mui-disabled {
    opacity: 0.7 !important;
  }
}

.add-viewed-apartment-modal-item {
  color: black;
  font-weight: 600;
  padding-left: 5px !important;
  border: 0px !important;
  text-transform: capitalize;
}
.add-viewed-apartment-modal-item:hover {
  color: white;
  background-color: transparent !important;
}

.disabled-wrapped-input {
  color: #989898;
}

.radio-button-modify-client {
  .MuiSvgIcon-root {
    height: 29px;
    width: 29px;
  }
}

.delete-client-button {
  background-color: #f35959 !important;
  color: white !important;
  font-size: 12px;
  height: 36px !important;
  text-transform: none;
  min-width: 120px !important;
}
.delete-client-button:hover {
  background-color: #f35959 !important;
}

.cancel-client-button {
  text-transform: none;
  border: 1px solid #e8e9eb !important;
  background-color: white !important;
  height: 36px !important;
  margin-right: 10px !important;
  min-width: 80px !important;
  font-size: 12px;
}

.cancel-client-button:hover {
  background-color: white !important;
}

.save-client-button {
  text-transform: none;
  background-color: #6bc1b4 !important;
  color: white !important;
  min-width: 80px !important;
  font-size: 12px;
  height: 36px !important;
}
.save-client-button:hover {
  background-color: #6bc1b4 !important;
}

.save-client-button.Mui-disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
}
.add-item-modal-switch {
  margin-left: 32px;
  margin-top: 50px;
}

.table-open-detail {
  min-width: 94px !important;
  height: 36px !important;
  padding: 10px 9.5px !important;
  background-color: #6bc1b4 !important;
  color: #f6f7f7 !important;
  font-size: 12px !important;
  text-transform: none !important;
}

// // AGGIUSTAMENTO TABELLE
.MuiTextField-root {
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    width: 100% !important;
  }
}

.MuiToolbar-regular {
  @media (max-width: $BREAKPOINT_MD) {
    min-height: 80px !important;
  }
}

.MuiTableCell-footer {
  padding-top: 16px !important;
  @media (max-width: $BREAKPOINT_MD) {
    padding-top: 0 !important;
  }
}
.table-proposal-and-quote {
  .MuiTableCell-footer {
    @media (max-width: 320px) {
      position: relative;
      left: -30px;
    }
  }
}

//----------------------------------

.add-account-modal-elements {
  width: 100%;
  height: 72px;
  display: flex;
  .add-item-modal-text-field {
    margin-left: 32px;
    margin-top: 16px;
    width: 100%;
    label {
      font-weight: 700 !important;
    }
  }
  .add-item-modal-text-field-right {
    margin-left: 32px;
    margin-right: 32px;
    margin-top: 16px;
    width: 100%;
    label {
      font-weight: 700 !important;
    }
  }
}
.add-item-modal-selector {
  margin-left: 32px;
  margin-top: 32px;
  width: 43.3%;
  label {
    font-weight: 700 !important;
  }
}

.border-bottom-edit-row {
  border-bottom: 2px solid #6bc1b4 !important;
  .MuiInput-input {
    font-weight: 700;
  }
  .MuiAutocomplete-root {
    min-width: 100px;
  }
  .MuiInputLabel-formControl {
    position: unset !important;
  }
  label + .MuiInput-formControl {
    margin-top: 0px;
  }
}

.circular-progress-in-lateral-modal {
  width: 100px !important;
  height: 100px !important;
  position: relative;
  margin-left: 50%;
  margin-top: 50%;
  left: -50px;
  top: -50px;
  color: #6bc1b4 !important;
}

.input-checkbox-container {
  .input-checkbox-main-container {
    display: flex;
    align-items: center;
    .input-checkbox-label {
      font-size: 14px;
      color: #989898;
      min-width: 100px;
    }
    .MuiSvgIcon-root {
      height: 30px;
      width: 30px;
    }
  }
  .input-checkbox-error {
    color: #f44336 !important;
  }

  .input-checkbox-error-helper {
    margin-top: 0;
    font-size: 12px;
    color: #f44336;
    padding-left: 5px;
  }
}

.circular-progress-in-proposal-manager {
  color: #6bc1b4 !important;
  height: 30px !important;
  width: 30px !important;
  position: relative;
  left: 0%;
}

.download-proposal-button {
  text-transform: capitalize !important;
  font-weight: 700 !important;
  font-size: 14px !important;
}

.download-proposal-button:hover {
  background-color: transparent !important;
}

.disabled-input {
  .MuiInputBase-root {
    margin-top: 10px !important;
  }
}

.MuiInput-underline.Mui-disabled:before {
  border-bottom: 0px;
}

.edit-profile-icon,
.impersona-icon {
  padding-right: 9px;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    padding: 0px;
  }

  div {
    display: flex;
  }
}

.link-input {
  .MuiInput-input {
    text-overflow: ellipsis;
  }
}

.copy-link-button {
  height: 32px;
  text-transform: none;
  background-color: #6bc1b4;
  color: white;
  box-shadow: none;
  margin-left: 16px;
  margin-top: 10px;
  font-size: 12px;
  min-width: 85px;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    position: relative;
    margin: 0px;
    right: -16px;
  }
}
.copy-link-button:hover {
  background-color: #6bc1b4;
  box-shadow: none;
}

.partecipate-link-button {
  height: 32px;
  text-transform: none;
  background-color: #6bc1b4;
  margin-left: 11px;
  color: white;
  box-shadow: none;
  margin-top: 10px;
  font-size: 12px;
  min-width: 115px;
}
.partecipate-link-button:hover {
  background-color: #6bc1b4;
  box-shadow: none;
}

.ok-calendar-button {
  background-color: #6bc1b4;
  width: 32px;
  height: 32px;
  align-items: center;
  display: flex;
  justify-content: center;
  color: white;
  font-size: 12px;
}
.ok-calendar-button:hover {
  background-color: #6bc1b4;
}
.cancel-calendar-button {
  width: 80px;
  height: 32px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: 1px solid #e8e9eb;
  color: #989898;
  font-size: 12px;
}
.cancel-calendar-button:hover {
  background-color: white;
}
.slot-event {
  background-color: #fff !important;
  border-radius: 0px !important;
  text-transform: none;
  font-family: 'SagoeRegular' !important;
  color: #989898;
}
.slot-event:hover {
  background-color: #fff !important;
}
.slot-event-active {
  color: #6bc1b4 !important;
  border: 1px solid #6bc1b4 !important;
  z-index: 2;
  font-weight: 700;
}
.slot-group {
  flex: 2;
  justify-content: center;
}

.hide-component {
  display: none;
}

.delete-margin {
  margin: 0 !important;
}
.client-manager-more-button {
  margin-bottom: 7px !important;
  margin-left: 4px !important;
  background-color: white !important;
  text-transform: none !important;
  color: #989898 !important;
  font-size: 12px !important;
  border: 1px solid #e8e9eb !important;
  min-width: 142px !important;
  height: 37px;
  @media (max-width: $BREAKPOINT_SM) {
    padding: 7px !important;
    min-width: 36px !important;
    margin: 0px !important;
    margin-left: 10px !important;
  }
}
.client-manager-more-button-disabled {
  margin-bottom: 7px !important;
  margin-left: 4px !important;
  background-color: rgba(232, 233, 235, 0.38) !important;
  text-transform: none !important;
  color: #cacbd3 !important;
  font-size: 12px !important;
  border: 1px solid #e8e9eb !important;
  min-width: 142px !important;
  height: 37px;
  @media (max-width: $BREAKPOINT_SM) {
    padding: 7px !important;
    min-width: 36px !important;
    margin: 0px !important;
    margin-left: 10px !important;
  }
}

.export-apartment-button {
  background-color: #6bc1b4 !important;
  font-size: 12px !important;
  color: white !important;
  text-transform: capitalize !important;
  margin-bottom: 7px !important;
  margin-left: 15px !important;
  padding: 7px 15px 7px 15px !important;
  height: 36px !important;
  @media (max-width: $BREAKPOINT_SM) {
    padding: 7px !important;
    min-width: 36px !important;
    margin: 0px !important;
  }
}
.import-apartment-button {
  background-color: #6bc1b4 !important;
  font-size: 12px !important;
  color: white !important;
  text-transform: capitalize !important;
  margin-bottom: 7px !important;
  margin-left: 4px !important;
  padding: 7px 15px 7px 15px !important;
  height: 36px !important;
  @media (max-width: $BREAKPOINT_SM) {
    padding: 7px !important;
    min-width: 36px !important;
    margin: 0px !important;
    margin-left: 10px !important;
    .edit-event-button {
      margin-top: 30px;
      background-color: #6bc1b4 !important;
      color: white !important;
      box-shadow: none;
      font-size: 12px;
      text-transform: none;
      height: 36px;
      @media (max-width: $BREAKPOINT_SMARTPHONE) {
        max-width: 36px;
        padding: 0px;
        min-width: 36px;
        margin-top: 0px;
      }
    }
    .edit-event-button:hover {
      background-color: #6bc1b4;
      color: white;
      box-shadow: none;
    }
    .disabled-edit-event-button {
      margin-top: 30px;
      background-color: #e0e0e0 !important;
      color: white !important;
      box-shadow: none;
      font-size: 12px;
      text-transform: none;
      height: 36px;
      @media (max-width: $BREAKPOINT_SMARTPHONE) {
        max-width: 36px;
        padding: 0px;
        min-width: 36px;
        margin-top: 0px;
      }
    }
    .disabled-edit-event-button:hover {
      background-color: #e0e0e0;
      color: white;
      box-shadow: none;
    }
    .modify-event-buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 25px;
      .delete-event-button {
        background-color: #f35959 !important;
        color: white !important;
        font-size: 12px;
        height: 36px !important;
        text-transform: none;
        min-width: 80px !important;
        box-shadow: none;
        margin-right: 10px;
        @media (max-width: $BREAKPOINT_SMARTPHONE) {
          height: 44px !important;
          min-width: 80px !important;
          margin-right: 5px;
        }
      }

      .delete-event-button:hover {
        background-color: #f35959 !important;
        box-shadow: none;
      }

      .delete-reassign-event-button {
        background-color: #f35959 !important;
        color: white !important;
        font-size: 11px;
        height: 36px !important;
        text-transform: none;
        box-shadow: none;
        margin-right: 10px;
        width: 100px;
        @media (max-width: $BREAKPOINT_SMARTPHONE) {
          height: 44px !important;
          width: 80px !important;
          margin-right: 5px;
          font-size: 10px;
        }
      }
      .delete-reassign-event-button:hover {
        background-color: #f35959 !important;
        box-shadow: none;
      }

      .cancel-event-button {
        text-transform: none;
        border: 1px solid #e8e9eb !important;
        background-color: white !important;
        color: #989898;
        height: 36px !important;
        margin-right: 10px !important;
        min-width: 80px !important;
        font-size: 12px;
        box-shadow: none;
        @media (max-width: $BREAKPOINT_SMARTPHONE) {
          height: 44px !important;
          min-width: 80px !important;
          margin-right: 5px !important;
        }
      }
      .cancel-event-button:hover {
        background-color: white !important;
        box-shadow: none;
      }
      .save-event-button {
        text-transform: none;
        background-color: #6bc1b4 !important;
        color: white !important;
        min-width: 80px !important;
        font-size: 12px;
        height: 36px !important;
        box-shadow: none;
        @media (max-width: $BREAKPOINT_SMARTPHONE) {
          height: 44px !important;
          min-width: 80px !important;
        }
      }
      .save-event-button:hover {
        background-color: #6bc1b4 !important;
        box-shadow: none;
      }
    }
  }
}

.calendar-settings-button {
  position: absolute !important;
  top: 0;
  right: 140px;
  border: 1px solid #ebe9eb !important;
  text-transform: none !important;
  font-size: 12px !important;
  border-radius: 0px;
  width: 150px;
  height: 35px;
  color: #989898 !important;
  margin-right: 10px;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    width: 36px;
    min-width: 36px;
    margin-right: 0px;
    right: 66px;
  }
}
.calendar-settings-button:hover {
  color: #6bc1b4 !important;
  background-color: white !important;
  border: 1px solid #6bc1b4 !important;
}

.calendar-settings-accordions-grid {
  .calendar-settings-accordion {
    width: 100%;
    height: 40px;
    padding: 0px;
    .MuiAccordionSummary-content.Mui-expanded {
      margin: 0px !important;
    }
    .MuiIconButton-edgeEnd {
      margin-right: 0px !important;
      padding: 0px !important;
    }
  }
  .MuiAccordion-root:before {
    background-color: transparent;
  }
  .calendar-settings-accordion-details {
    border-top: 0px !important;
    padding: 16px 0px 16px 0px !important;
  }
  .calendar-settings-accordion-details-out-office {
    border-top: 0px !important;
    padding: 0px !important;
  }
}
.calendar-colors-paper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 215px;
  height: 175px;
  background-color: white;
  border: 1px solid #e8e9eb;
  margin-top: 5px;
}

.color-text-field-selector {
  .MuiInputBase-input {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    display: inline-block;
    padding: 0px;
    margin-bottom: 6px;
    margin-left: 2px;
  }
  .MuiFormLabel-root {
    font-size: 10px !important;
    top: -25px !important;
  }
  .MuiFormLabel-root.Mui-focused {
    top: -2px !important;
    font-size: 13px !important;
  }
}

.cross-chip-calendar-settings {
  position: relative;
  width: 8px;
  height: 8px;
}
.cross-chip-calendar-settings::before,
.cross-chip-calendar-settings::after {
  position: absolute;
  top: -1px;
  left: -1px;
  content: ' ';
  height: 12px;
  width: 1px;
  background-color: #989898;
}
.cross-chip-calendar-settings::before {
  transform: rotate(45deg);
}
.cross-chip-calendar-settings::after {
  transform: rotate(-45deg);
}
.calendar-settings-out-office {
  .input-wrapper-container {
    margin: 16px 16px 16px 0px !important;
    .input-wrapper-icon-container {
      padding-left: 0px !important;
      padding-right: 9px !important;
      width: 30px;
    }
    .input-wrapper-input-component {
      margin-left: 16px !important;
    }
  }
  .checkbox-calendar-settings {
    position: relative;
    left: -16px;
  }
}

.calendar-settings-repetOn {
  display: contents;

  .input-wrapper-container {
    margin: 0px !important;
    height: fit-content;
    min-width: fit-content;
    .input-checkbox-label {
      min-width: 80px;
    }
  }
  .MuiGrid-item {
    min-width: fit-content;
  }
  .MuiGrid-grid-md-2 {
    flex-basis: auto;
  }
}

.date-range-calendar-out-of-office {
  position: absolute;
  right: -2px;
  top: 140px;
  z-index: 1800;
  background-color: white !important;
  border: 1px solid #dddbda;
  @media (max-width: $BREAKPOINT_MD) {
    top: 240px;
    right: -10px;
  }
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    right: -10px;
    top: 220px;
  }
  .customPrevButtonClassName i {
    border: solid black !important;
    border-width: 0 1px 1px 0 !important;
    display: inline-block !important;
    padding: 3px !important;
    transform: rotate(135deg) !important;
    -webkit-transform: rotate(135deg) !important;
  }

  .customNextButtonClassName i {
    border: solid black !important;
    border-width: 0 1px 1px 0 !important;
    display: inline-block !important;
    padding: 3px !important;
    transform: rotate(45deg) !important;
    -webkit-transform: rotate(-45deg) !important;
  }
  .rdrMonthAndYearWrapper {
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    .rdrNextPrevButton {
      background-color: transparent;
    }
  }
  .rdrMonth {
    padding-bottom: 8px;
    .rdrMonthName {
      display: none;
    }
    .rdrWeekDays {
      justify-content: center;
      .rdrWeekDay {
        color: black;
        font-size: 12px;
        font-weight: 700;
        width: 40px;
        height: 40px;
        padding-right: 4px;
      }
    }
  }
  .rdrDay {
    height: 40px;
  }
  .rdrDayNumber {
    font-size: 12px;
    font-weight: 400;
    height: 100%;
    span {
      height: 100%;
      width: 100%;
    }
    width: 100%;
    top: 0px;
  }
  .rdrDayStartOfMonth .rdrDayInPreview,
  .rdrDayStartOfMonth .rdrDayEndPreview,
  .rdrDayStartOfWeek .rdrDayInPreview,
  .rdrDayStartOfWeek .rdrDayEndPreview {
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    border-left-width: 0px;
  }
  .rdrDayEndOfMonth .rdrDayInPreview,
  .rdrDayEndOfMonth .rdrDayStartPreview,
  .rdrDayEndOfWeek .rdrDayInPreview,
  .rdrDayEndOfWeek .rdrDayStartPreview {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right-width: 0px;
  }
  .rdrDayStartOfMonth .rdrInRange,
  .rdrDayStartOfMonth .rdrEndEdge,
  .rdrDayStartOfWeek .rdrInRange,
  .rdrDayStartOfWeek .rdrEndEdge {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .rdrDayEndOfMonth .rdrInRange,
  .rdrDayEndOfMonth .rdrStartEdge,
  .rdrDayEndOfWeek .rdrInRange,
  .rdrDayEndOfWeek .rdrStartEdge {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .rdrDayStartPreview,
  .rdrDayInPreview,
  .rdrDayEndPreview {
    //eliminazione bordo quando vai sopra
    border: 0px;
    background-color: rgba(107, 193, 180, 0.1);
    height: 100%;
    width: 100%;
    border-radius: 0px;
    top: 0px;
    bottom: 0px;
  }
  .rdrDayHovered {
    background-color: rgba(107, 193, 180, 0.1);
    top: 0px;
    bottom: 0px;
  }
  .rdrDayPassive {
    visibility: hidden;
  }
  .rdrStartEdge,
  .rdrEndEdge {
    background-color: #6bc1b4;
    border: 0px;
    border-radius: 0px;
    height: 100%;
    width: 100%;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
  }
  .rdrInRange {
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    border: 0px;
    border-radius: 0px;
    background-color: rgba(107, 193, 180, 0.1);
    height: 100%;
    width: 100%;
  }
  .rdrInRange ~ .rdrDayNumber span {
    //seleziona ogni rdrDayNumber che viene preceduto da un rdrInRange
    color: black !important;
  }
  .rdrDayToday .rdrDayNumber span {
    color: #009688;
    font-weight: 700;
  }
  .rdrDayToday .rdrDayNumber span:after {
    background: transparent !important;
  }
  .rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after {
    background: transparent;
  }
  .rdrDayStartOfMonth .rdrInRange,
  .rdrDayStartOfMonth .rdrEndEdge,
  .rdrDayStartOfWeek .rdrInRange,
  .rdrDayStartOfWeek .rdrEndEdge {
    left: 0px;
  }
}

.out-of-office-events {
  width: 100%;
  height: 70px;
  margin-bottom: 8px;
  border: 1px solid #dddbda;
  font-size: 14px;
}

.recurring-events {
  width: 100%;
  height: 85px;
  margin-bottom: 8px;
  border: 1px solid #dddbda;
  font-size: 14px;
}

.radio-gray {
  .MuiIconButton-label {
    color: #989898;
  }
}

.center-radio {
  margin: 0px !important;
}

.radio-left {
  position: relative;
  left: -2px;
}
.confirmed-validation-menu {
  background-color: white !important;
  margin-top: 19px;
  margin-left: -5px;
  border: 0px !important;
  .confirmed-validation-item {
    width: 155px !important;
    font-size: 14px;
    border: 0px;
    padding-left: 10px !important;
    height: 30px;
    cursor: pointer;
  }
}

.delete-recurring-event-button {
  height: fit-content;
  min-width: fit-content;
  padding: 0px;
}
.delete-recurring-event-button:hover {
  background-color: transparent;
}
.plus-button-add-item-modal {
  min-width: 16px !important;
  margin-left: 16px !important;
}
.plus-button-add-item-modal:hover {
  background-color: white !important;
}

.delete-user-info-manager {
  padding: 0px !important;
  min-width: fit-content !important;
  margin-left: 10px !important;
  color: #cccccc !important;
}
.delete-user-info-manager:hover {
  background-color: white !important;
  color: #f35959 !important;
}

.to-left-15px {
  position: relative;
  left: -15px;
}

.calendar-range-lista-preventivi {
  .date-range-calendar {
    @media (max-width: $BREAKPOINT_MD) {
      position: absolute;
      top: 140%;
      right: -266px;
    }
    @media (max-width: 740px) {
      position: absolute;
      top: 140%;
      right: -266px;
    }
  }
}

.calendar-range-lista-proposte {
  .date-range-calendar {
    @media (max-width: $BREAKPOINT_MD) {
      position: absolute;
      top: 140%;
      right: -133px;
    }
    @media (max-width: 740px) {
      position: absolute;
      top: 140%;
      right: -133px;
    }
  }
}

.lista-clienti-date-range {
  .date-range-calendar {
    @media (max-width: $BREAKPOINT_MD) {
      position: absolute;
      top: 140%;
      right: -133px;
    }
    @media (max-width: 740px) {
      position: absolute;
      top: 140%;
      right: -133px;
    }
  }
}

.appartmentTabs {
  width: 150px !important;
  @media (max-width: $BREAKPOINT_MD) {
    width: unset !important;
  }
}

.add-notification-container {
  width: 100%;
  height: 100%;
  margin-top: 10%;
  margin-left: 18%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media (max-width: $BREAKPOINT_MD) {
    margin-left: 0%;
    text-align: initial;
  }
}

.add-notification-sub-container-top {
  width: 100%;
  padding: 1rem;
  &:nth-of-type(2) {
    padding-top: 0;
  }
  @media (min-width: $BREAKPOINT_SM) {
    &:nth-of-type(2) {
      padding-top: 1rem;
      padding-left: 0;
    }
  }
}

.add-notification-sub-container-bottom {
  width: 100%;
  height: 100%;
  margin-top: 15px;

  @media (max-width: $BREAKPOINT_MD) {
    width: auto;
    margin-top: 23px;
  }
}

.add-notification-title {
  font-weight: 900;
  color: #6bc1b4;
  font-size: 25px;

  @media (max-width: $BREAKPOINT_MD) {
    font-size: 22px;
  }
}

.add-notification-subtitle {
  font-weight: bold;
  font-size: 14px;
  margin-top: 25px;
  margin-bottom: 25px;

  @media (max-width: $BREAKPOINT_MD) {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 0px;
  }
}

.add-notification-subtitle-dashboard {
  font-weight: bold;
  margin-top: 8px;
  font-size: 13px;

  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    margin-top: 0px;
  }
}

.add-notification {
  width: 681px;
  height: 254px;
  background-color: white;
  display: flex;

  @media (max-width: $BREAKPOINT_MD) {
    width: 474px;
    height: 114px;
  }
}

.add-notification-image-container {
  width: 240px;
  height: auto;
  background-color: #6bc1b4;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: $BREAKPOINT_MD) {
    width: 197px;
  }
}

.add-notification-image-container-dashboard {
  width: 40%;
  height: auto;
  background-color: #6bc1b4;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    display: none;
  }
}

.add-notification-text {
  width: 70%;
  align-self: center;

  @media (max-width: $BREAKPOINT_MD) {
    display: flex;
  }
}

.add-notification-text-position {
  @media (max-width: $BREAKPOINT_MD) {
    margin-left: 16px;
    width: 150px;
  }
}

.add-notification-text-dashboard {
  width: 70%;
  align-self: center;
  display: flex;
  margin: 10px;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.container-svg-style {
  width: 90%;
  max-width: 135px;
}

.notification-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &.grey-border {
    width: auto;
    /* padding: 1rem; */
    border: 1px solid #e8e9eb;
  }

  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    flex-direction: column;
  }
}

.add-notification-margin {
  flex: 2;
  margin: 1rem 0;
}

.white {
  background-color: transparent;
}

.grey-border {
  border: 1rem solid rgba($color: #f0f2f2, $alpha: 1);
  margin: 10px;
  background-color: #f0f2f2;
}

.dashboard-container {
  .MuiGrid-grid-xs-10 {
    max-width: 100%;
  }

  .add-notification-title {
    font-size: 20px;

    @media (max-width: $BREAKPOINT_SMARTPHONE) {
      font-size: 16px;
    }
  }

  .add-notification-dashboard {
    background-color: white;
    display: flex;
    width: 100%;
    height: 100%;
  }

  tbody.MuiTableBody-root > :nth-of-type(2n) {
    background-color: white !important;
  }

  tbody.MuiTableBody-root > :nth-of-type(1n) {
    border-bottom: 1px solid #cacaca;
  }

  tbody.MuiTableBody-root {
    border-bottom: 1px solid #e8e9eb;
    border-left: 1px solid #e8e9eb;
    border-right: 1px solid #e8e9eb;
  }

  .MuiTab-root {
    &:after {
      content: '';
      border-bottom: 1px solid #e8e9eb;
    }
  }

  .button-container-notifications {
    flex: 1;
    margin-left: 5px;
    text-align: end;
    .MuiButton-root {
      min-width: 85px;
    }
    @media (min-width: $BREAKPOINT_SM) {
      text-align: center;
    }
  }

  .MuiTableHead-root {
    display: none;
  }

  .grey-border-table {
    .MuiAppBar-colorPrimary {
      background-color: #f0f2f2;
    }

    .MuiTab-textColorInherit.Mui-selected {
      background-color: white;
    }
  }
}

.grey-border-table {
  border: 1px solid #e8e9eb;
  padding: 1rem;
  background-color: #f0f2f2;
  margin: 10px;
}

.dashboard-container .MuiTab-textColorInherit.Mui-selected {
  &:after {
    content: '';
    border-bottom: 1px solid #e8e9eb;
  }
}

.modal-mood-content {
  overflow: auto;
  width: 18em;
  align-self: center;
}

.modal-mood-title {
  color: #db7a7d;
}

.MuiTab-labelIcon {
  min-height: 55px !important;
}

.MuiTab-labelIcon .MuiTab-wrapper > *:first-child {
  margin-top: 6px !important;
  margin-bottom: 0px !important;
  margin-right: 16px !important;
}

.MuiTab-wrapper {
  flex-direction: row !important;
}

.action-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.MuiGrid-item {
  margin-top: -1px !important;
}

.availability-input {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  &-tooltip {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    max-width: 100px;
    font-size: 12px;
    color: #fff;
    font-family: 'SagoeRegular';
    strong {
      font-family: 'SagoeBold';
    }
  }
}
