@import 'styles/colors.scss';
@import 'styles/variables.scss';

.white {
  background-color: transparent;
}

.grid-item {
  background-color: white;
  height: 56px;
  display: flex;
  align-items: center;
}

.tab-icon {
  margin: 0 15px 0 5px;
}
