@import 'styles/colors.scss';
@import 'styles/variables.scss';
@import 'styles/main.scss';

.horizontal-bar-label {
  padding-left: 5px;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  .horizontal-bar-label-name {
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .horizontal-bar-stats {
    display: inline;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 7px;
    .horizontal-bar-percentual-stat {
      font-weight: 900;
      width: 20px;
      text-align: end;
    }
  }
}

.horizontal-bar-value {
  align-self: center;
  width: 5px;
}

.donut-legend-icon {
  @extend .circle;
  margin: 5px 8px 5px 5px !important;
}

.donut-legend-text {
  max-width: 80px;
}

.border-bottom {
  border-bottom: 1px solid $BACKGROUNDS;
}

.light-font {
  color: $LIGHT_FONT_COLOR !important;
}

.recharts-tooltip-wrapper {
  z-index: 2;
  overflow-wrap: break-word !important;
  .custom-tooltip {
    display: flex;
    flex-flow: column;
    justify-content: center;
    white-space: initial;
    position: relative;
    background: rgba(30, 32, 31, 0.6);
    border-color: transparent;
    border-radius: 4px;
    min-height: 40px;
    max-width: 120px;
    overflow-wrap: break-word;
    height: auto;
    text-align: center;
    .recharts-tooltip-label {
      text-transform: capitalize;
      margin: 0;
      padding: 5px;
      color: white;
      font-size: 12px;
    }
  }
  .custom-tooltip::after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-right-color: rgba(30, 32, 31, 0.6);
    border-left: 0;
    margin-top: -5px;
    margin-left: -5px;
  }
}

.donut-legend-stats {
  justify-content: flex-end;
  display: flex;
  flex: 1;
  padding-right: 10px;
  align-items: baseline;
  .donut-legend-money-stats {
    font-size: 15px;
    font-weight: 600;
    width: 90px;
    display: flex;
    justify-content: flex-end;
  }
}

.MuiGrid-grid-sm-6:nth-of-type(odd) {
  @media (max-width: 1240px) {
    .donut-legend-stats {
      justify-content: flex-start;
      position: absolute;
      left: 100px;
    }
  }
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    .donut-legend-stats {
      position: unset;
      justify-content: flex-end;
    }
  }
}
.MuiGrid-grid-sm-6:nth-of-type(even) {
  @media (max-width: 1240px) {
    .donut-legend-stats {
      justify-content: flex-end;
    }
    .legend-right-side {
      position: absolute;
      right: 160px;
      width: 90px;
    }
  }
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    .legend-right-side {
      position: unset;
    }
    .donut-legend-stats {
      position: unset;
    }
  }
}

text.recharts-text.recharts-cartesian-axis-tick-value {
  font-size: 13px;
}
.recharts-responsive-container {
  height: 100%;
}
.doughnut-chart-container {
  @media (max-width: $BREAKPOINT_MD) {
    padding-bottom: 30px;
  }
  @media (max-width: $BREAKPOINT_SM) {
    padding-bottom: 90px;
  }
}
.legend-content {
  display: flex;
  flex-flow: row wrap;
}

.verticalbar-legend-content {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 200px;
}

.recharts-legend-wrapper {
  bottom: -20px !important;
  @media (max-width: $BREAKPOINT_SM) {
    bottom: -70px !important;
  }
}
.vertical-bar-legend-container {
  position: absolute;
  bottom: -55px;

  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    position: absolute;
    bottom: -30px;
  }
  @media (min-width: $BREAKPOINT_MD) {
    position: absolute;
    bottom: -30px;
  }
}
.vertical-bar-legend-item {
  display: flex;
  margin-right: 10px;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    margin: 0;
    padding-right: 5px;
  }
}
.recharts-surface {
  overflow: visible;
}

.vertical-bar-container {
  @media (max-width: $BREAKPOINT_MD) {
    padding-bottom: 90px;
  }
}
