@import 'styles/colors.scss';
@import 'styles/variables.scss';
@import 'styles/main.scss';

.filled {
  .input-default {
    color: $EDITING_COLOR !important;
  }
  .small-label-font {
    color: $EDITING_COLOR !important;
    font-size: $SMALL_LABEL_FONT;
  }
}

.input-default {
  @extend .bold;
  text-overflow: ellipsis;
  font-size: $BIGGER_FONT !important;
}

.input-text {
  .MuiInputBase-input {
    &::placeholder {
      font-style: italic;
    }
  }
}

.input-table-filter {
  @extend .bold;
  text-overflow: ellipsis;

  .MuiInputBase-input {
    border-bottom-color: $BACKGROUNDS !important;
    margin-bottom: 7px;
  }

  .MuiInput-underline {
    &.Mui-disabled:before,
    &:before,
    &:hover:not(.Mui-disabled):before {
      border-bottom-style: solid !important;
      border-bottom-color: $BACKGROUNDS !important;
    }
  }

  .event-form-spacing {
    padding: 1rem;
  }
  .event-form-spacing-edit {
    padding: 1rem;
    padding-top: 50px;
  }
  .drop-zone-container {
    border: 2px dashed $DROPZONE_BORDER;
    flex-grow: 1;
    max-height: 45px;
    padding: 10px 20px;
    border-radius: 20px;
    margin-right: 42px;
  }
  .client-form-spacing .MuiInput-underline {
    &.Mui-disabled:before,
    &:before,
    &:hover:not(.Mui-disabled):before {
      border-bottom-style: solid !important;
      border-bottom-color: $DISABLED !important;
    }
  }

  .event-form-spacing {
    padding: 1rem;
  }
  .drop-zone-container {
    border: 2px dashed $DROPZONE_BORDER;
    flex-grow: 1;
    max-height: 45px;
    padding: 10px 20px;
    border-radius: 20px;
    margin-right: 42px;
  }

  .file-input-bin-placeholder {
    width: 42px;
  }

  .positioned-file-input-image {
    z-index: 1;
    width: 15px;
    top: 88px;
    right: 50px;
    height: 15px;
    position: absolute;
  }

  .error-text-file-input {
    color: $ERROR_COLOR;
  }

  .very-small-font {
    font-size: $VERY_SMALL_FONT;
    color: $ERROR_COLOR;
    font-weight: $BOLD;
  }

  .MuiPickersDay-day {
    .MuiIconButton-label {
      .MuiTypography-body2 {
        font-size: 12px !important;
        font-family: 'SagoeRegular';
      }
    }
  }
  .MuiDialogActions-root {
    padding: 10px;
    .MuiButton-textPrimary:hover {
      background-color: transparent !important;
    }
    .MuiButton-text {
      padding: 0px;
    }
    .MuiButton-root {
      min-width: fit-content;
    }
  }
  .MuiDialogActions-spacing > :not(:first-child) {
    margin-left: 1rem !important;
  }

  .arrow {
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 5px;
  }
  .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  .left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
}

.MuiDialogActions-root {
  button {
    font-family: 'SagoeRegular';
    &:first-child {
      margin-top: 10px;
      margin-bottom: 10px;
      height: 36px;
      min-width: 80px;
      background-color: white;
      border: 1px solid #e8e9eb;
      color: #989898;
      font-size: 14px;
    }
    &:last-child {
      background-color: #6bc1b4;
      margin-left: 1rem;
      margin-right: 1rem;
      margin-top: 10px;
      margin-bottom: 10px;
      height: 36px;
      min-width: 32px;
      color: white;
      font-size: 14px;
    }
  }
}

.arrow {
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 4px;
}
.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

//////////////////////////////////
.rc-time-picker-input {
  font-size: 14px !important;
  color: black !important;
  width: 40px !important;
  font-family: 'Sagoe';
  font-weight: 600;
  border: 0px !important;
  border-radius: 0px !important;
  padding-left: 5px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  padding-right: 0px !important;
  margin-right: 5px;
  outline: none;
  cursor: pointer !important;
}

.rc-time-picker-input[disabled] {
  background-color: white !important;
  cursor: context-menu !important;
}

.rc-time-picker-panel-inner {
  top: 40px;
  height: 230px;
  width: 166px;
  box-shadow: none !important;
  border: 1px solid #e8e9eb !important;
  border-radius: 0px !important;
  left: -22px;
}
.rc-time-picker-panel-select:first-child {
  width: 83px !important;
  min-height: 228px !important;
  overflow-y: auto;
}
.rc-time-picker-panel-select::-webkit-scrollbar {
  width: 1rem;
}
.rc-time-picker-panel-select::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.02);
  border-left: 1px solid #e8e9eb;
}
.rc-time-picker-panel-select::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.51);
  border: 4px solid transparent;
  border-radius: 9px;
  background-clip: content-box;
}

.rc-time-picker-panel-input-wrap {
  display: none;
}

.rc-time-picker-panel-select li {
  padding: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px !important;
  font-weight: 700;
}

.rc-time-picker-panel-select li:hover {
  background-color: #6bc1b4 !important;
}
.rc-time-picker-panel-select:last-child {
  width: 82px !important;
  min-height: 228px !important;
  overflow-y: auto;
}

.time-range-label {
  font-size: 11px;
  position: relative;
  top: -5px;
  color: #989898;
}

.edit-quote-input {
  .MuiInput-underline.MuiInput-underline.MuiInput-underline.MuiInput-underline:before {
    border-bottom: 1px solid #cacbd3;
  }
  .MuiAutocomplete-inputRoot[class*='MuiInput-root'] .MuiAutocomplete-input {
    padding: 6px;
  }
  &.edit-price {
    position: relative;
  }
}
.input-loader {
  position: absolute;
  bottom: 1.5rem;
  right: 1.5rem;
}

.switch-label {
  margin-left: 5px;
  margin-right: 10px;
  width: 65px;
}

.temporarily-disabled-icon {
  width: 1.5rem;
}

.route-link {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  &-label {
    color: #989898;
    font-size: 10.5px;
    top: 4px;
    position: absolute;
  }
  &-value {
    color: #6bc1b4;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 1rem;
    display: flex;
    align-items: center;
  }
}
